<template>
  <div v-if="user" class="relative inline-flex">
    <button ref="trigger" class="inline-flex justify-center items-center group" aria-haspopup="true"
            @click.prevent="toggleDropdown" :aria-expanded="dropdownOpen">
      <div class="flex items-center justify-center w-12 h-12">
        <div :class="[
    'w-4 h-4 rounded-full items-center justify-center mb-5',
    unreadCount > 0 ? 'bg-red-500' : 'bg-green-100'
  ]">
          <div class="text-xs text-white">{{ unreadCount }}</div>
        </div>
        <Notification/>
      </div>
    </button>
    <transition enter-active-class="transition ease-out duration-200 transform"
                enter-from-class="opacity-0 -translate-y-2" enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition ease-out duration-200" leave-from-class="opacity-100"
                leave-to-class="opacity-0">
      <div v-show="dropdownOpen"
           class="origin-top-right z-50 absolute top-full w-96 bg-white border border-grey-50 p-5 rounded-xl shadow-lg overflow-hidden mt-1 divide-zinc-100 divide-y"
           :class="align === 'right' ? 'right-0' : 'left-0'">
        <div class="flex flex-row gap-2 mt-1 mb-2">
          <div class="flex justify-between items-center mt-1 mb-2 w-full">
            <div class="text-md font-bold text-black">Notifications</div>
            <button
                v-if="notifications.length > 0"
                @click="hideAllNotifications"
                :disabled="unreadCount === 0"
                class="flex items-center"
                :class="unreadCount === 0 ? 'text-gray-400' : 'text-green-100 hover:text-green-200'"
                title="Masquer toutes les notifications"
            >
              <BellSlashIcon class="w-5 h-5" />
            </button>
          </div>
        </div>
        <div ref="dropdown" @focusin="dropdownOpen = true" @focusout="dropdownOpen = false"
             class="divide-grey-200 divide-y">
          <div v-if="notifications.length > 0">
            <div v-for="notification in notifications" :key="notification.id" class="py-2">
              <div class="flex flex-col space-y-2">
                <div>
                  <h4 class="text-sm font-medium text-gray-800">{{ notification.data.title }}</h4>
                  <p class="text-xs text-gray-500 mt-1 overflow-auto max-h-48">{{ notification.data.desc }}</p>
                </div>
                <div class="flex justify-between items-center mt-2">
                  <a v-if="notification.data.url"
                     :href="notification.data.url"
                     class="text-xs font-semibold text-green-100 hover:text-green-200">
                    &raquo; Voir
                  </a>
                  <div v-else class="flex-grow"></div> <!-- Espace réservé si pas de lien "Voir" -->
                  <button v-if="!notification.read_at"
                          @click="markAsRead(notification.id)"
                          class="text-xs text-green-100 hover:text-green-200 ml-auto">
                    Marquer comme lu
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <p class="w-full font-medium text-sm text-black flex items-center pt-3 mb-3">Aucune notification</p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import axios from 'axios';
import {usePage} from '@inertiajs/vue3';
import {computed, onMounted, onUnmounted, ref} from 'vue';
import {BellSlashIcon} from '@heroicons/vue/24/outline'

import Notification from '@/Components/Client/Icons/Notification.vue';


defineProps(['align']);

const dropdownOpen = ref(false);
const trigger = ref(null);
const dropdown = ref(null);
const user = computed(() => usePage().props?.auth?.user);
const notifications = ref([]);
const unreadCount = ref(0);

const fetchNotifications = async () => {
  try {
    const response = await axios.get('/notifications');
    notifications.value = response.data;
    unreadCount.value = notifications.value.filter(n => !n.read_at).length;
  } catch (error) {
    console.error('Error fetching notifications:', error);
  }
};

const hideAllNotifications = async () => {
  if (unreadCount.value === 0) return; // Ne rien faire si pas de notifications non lues
  try {
    await axios.post('/notifications/hide-all');
    await fetchNotifications(); // Rafraîchir la liste des notifications
  } catch (error) {
    console.error('Erreur lors du masquage des notifications:', error);
  }
};

const markAsRead = async (id) => {
  try {
    await axios.post(`/notifications/${id}/read`);
    await fetchNotifications();
  } catch (error) {
    console.error('Error marking notification as read:', error);
  }
};

const clickHandler = ({target}) => {
  if (!dropdownOpen.value || dropdown.value.contains(target) || trigger.value.contains(target)) return;
  dropdownOpen.value = false;
};

const keyHandler = ({keyCode}) => {
  if (!dropdownOpen.value || keyCode !== 27) return;
  dropdownOpen.value = false;
};

const toggleDropdown = () => {
  dropdownOpen.value = !dropdownOpen.value;
  if (dropdownOpen.value) {
    fetchNotifications();
  }
};

onMounted(() => {
  document.addEventListener('click', clickHandler);
  document.addEventListener('keydown', keyHandler);
  fetchNotifications();
});

onUnmounted(() => {
  document.removeEventListener('click', clickHandler);
  document.removeEventListener('keydown', keyHandler);
});
</script>

